import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import translations from "../translations.json";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { API_BASE_URL } from '../url';

function EditCar() {
    let { carId } = useParams();
    const navigate = useNavigate();

    function translate(text) {
        return translations[text] || text.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    const [changedData, setChangedData] = useState({});
    const [carData, setCarData] = useState({});
    const [serviceIntervalData, setServiceIntervalData] = useState({});
    const [driversData, setDriversData] = useState([]);
    const [brandsData, setBrandsData] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);

    useEffect(() => {
        fetch(`${API_BASE_URL}api/cars/get?id=${carId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${document.cookie.split("=")[1]}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then(response => response.json())
            .then(data => {
                setCarData(data.car);
                setServiceIntervalData(data.serviceInterval);
            })
            .catch(error => console.error("Error fetching car data:", error));

        fetchBrandsData();

        fetch(`${API_BASE_URL}api/drivers/getAll`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${document.cookie.split("=")[1]}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then(response => response.json())
            .then(setDriversData)
            .catch(error => console.error("Error fetching drivers data:", error));
    }, [carId]);

    useEffect(() => {
        if (brandsData.length > 0 && carData.brand) {
            const selected = brandsData.find(brand => brand.label === carData.brand);
            if (selected) {
                setSelectedBrand([selected]);
            }
        }
    }, [brandsData, carData.brand]);

    const fetchBrandsData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}api/cars/brands/get_all`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${document.cookie.split("=")[1]}`,
                    "ngrok-skip-browser-warning": "69420",
                }
            });
            const data = await response.json();
            const sortedBrands = data
                .map(brand => ({ id: brand.id, label: brand.name }))
                .sort((a, b) => a.label.localeCompare(b.label));
            setBrandsData(sortedBrands);
        } catch (error) {
            console.error("Error fetching brands data:", error);
        }
    };

    const handleChangeCarData = (event) => {
        const { name, value, files } = event.target;
        if (name === 'image') {
            setCarData(prevData => ({
                ...prevData,
                [name]: files[0]
            }));
            setChangedData(prevData => ({
                ...prevData,
                [name]: files[0]
            }));
        } else {
            setCarData(prevData => ({
                ...prevData,
                [name]: value
            }));
            setChangedData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleBrandSelection = (selected) => {
        setSelectedBrand(selected);
        if (selected.length > 0) {
            const updatedCarData = {
                ...carData,
                brand: selected[0].label
            };
            setChangedData(updatedCarData);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        const { status, ...fieldsToSend } = changedData;

        const dataObject = {
            id: carId,
            fields: { ...fieldsToSend },
        };

        delete dataObject.fields.image;

        formData.append('data', JSON.stringify(dataObject));

        if (changedData.image instanceof File) {
            formData.append('image', changedData.image, changedData.image.name);
        }

        fetch(`${API_BASE_URL}api/cars/update`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${document.cookie.split("=")[1]}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(() => {
                alert('Údaje o autě byly úspěšně aktualizovány');
                navigate(-1);
            })
            .catch(error => {
                console.error("Error updating car data:", error);
                alert('Chyba: Nepodařilo se aktualizovat údaje o autě.');
            });
    };

    if (!carData || !serviceIntervalData) return <div>Načítání...</div>;

    const excludedFields = ['image', 'park_id', 'service_interval_id', 'id'];

    return (
        <Container>
            <Form onSubmit={handleSubmit} className='w-75'>
                {Object.keys(carData).filter(key => !excludedFields.includes(key)).map(key => {
                    if (key === "photo") return null;
                    if (key === "status") return null;
                    if (key === "next_service_mileage") return null;
                    if (key === "next_tire_change") return null;

                    const label = translate(key);
                    if (key === "brand") {
                        return (
                            <Form.Group className="mb-3" key={key}>
                                <Form.Label>{label}</Form.Label>
                                <Typeahead
                                    id="brand-typeahead"
                                    onChange={handleBrandSelection}
                                    options={brandsData}
                                    placeholder="Select a brand"
                                    selected={selectedBrand}
                                    clearButton
                                />
                            </Form.Group>
                        );
                    } else {
                        const isSelectField = key === "tire_type" || key === "driver_id";
                        const selectOptions = key === "tire_type" ? [
                            { value: "0", label: "Zimní" },
                            { value: "1", label: "Letní" },
                            { value: "-1", label: "Celoroční" },
                        ] : driversData.map(driver => ({
                            value: driver.id.toString(),
                            label: `${driver.first_name} ${driver.last_name}`
                        }));

                        return (
                            <Form.Group className="mb-3" key={key}>
                                <Form.Label>{label}</Form.Label>
                                {isSelectField ? (
                                    <Form.Select
                                        name={key}
                                        value={carData[key]}
                                        onChange={handleChangeCarData}
                                    >
                                        <option>Zadejte</option>
                                        {selectOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                ) : (
                                    <Form.Control
                                        type="text"
                                        name={key}
                                        placeholder={`Zadejte ${label}`}
                                        value={carData[key]}
                                        onChange={handleChangeCarData}
                                    />
                                )}
                            </Form.Group>
                        );
                    }
                })}

                <div className="d-flex justify-content-between">
                    <Button variant="outline-secondary" onClick={() => navigate(-1)}>Zrušit</Button>
                    <Button variant="primary" style={{ background: "rgb(182, 51, 46)", border: "none" }} type="submit">
                        Uložit změny
                    </Button>
                </div>
            </Form>
        </Container>
    );
}

export default EditCar;
