import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { API_BASE_URL } from "./Components/url";

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, setIsAuthenticated } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const cookie = document.cookie;
    const sessionId = cookie.split("=")[1];

    const deleteCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    const verifySession = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}api/parks/sessions/verify`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${sessionId}`,
                    "ngrok-skip-browser-warning": "69420",
                },
            });

            const data = await response.json();
            console.log(data);

            if (data.detail === "Wrong session id") {
                deleteCookie('session_id');
                setIsAuthenticated(false);
            } else {
                setIsAuthenticated(true);
            }
        } catch (error) {
            console.error('Error verifying session:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        verifySession();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
