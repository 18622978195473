import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import translations from "../translations.json";
import InputMask from 'react-input-mask';
import { API_BASE_URL } from '../url';

function AddDriver() {
    const [driverData, setDriverData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        whatsapp: '',
        parkId: '',
        image: null,
    });
    const [parks, setParks] = useState([]);
    const cookie = document.cookie;
    let sessionId = cookie.split("=")[1];
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${API_BASE_URL}api/parks/getAll`, {
            method: "GET",
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${sessionId}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then(response => response.json())
            .then(data => {
                setParks(data);
            })
            .catch(error => {
                console.error("Error fetching parks:", error);
            });
    }, []);

    function translate(key) {
        return translations[key] || key;
    }

    const handleChange = (event) => {
        const { name, value, files } = event.target;
        if (files) {
            setDriverData(prevData => ({
                ...prevData,
                [name]: files[0]
            }));
        } else {
            setDriverData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('data', JSON.stringify({
            first_name: driverData.firstName,
            last_name: driverData.lastName,
            phone_number: driverData.phoneNumber,
            whatsapp: driverData.whatsapp,
            park_id: driverData.parkId,
            photo: null
        }));

        if (driverData.image) {
            formData.append('image', driverData.image, `${driverData.firstName}_${driverData.lastName}.jpg`);
        }

        fetch(`${API_BASE_URL}api/drivers/add`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${sessionId}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                alert('Řidič úspěšně přidán!');
                navigate(-1);
            })
            .catch(error => {
                console.error("Error submitting form:", error);
                alert('Chyba: Nepodařilo se přidat řidiče.');
            });
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <>
            <Container>
                <Form onSubmit={handleSubmit} className='w-75'>
                    <Form.Group className="mb-3">
                        <Form.Label>{translate("First Name")}</Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            placeholder={translate("Enter First Name")}
                            value={driverData.firstName}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{translate("Last Name")}</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            placeholder={translate("Enter Last Name")}
                            value={driverData.lastName}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{translate("Phone Number")}</Form.Label>
                        <InputMask
                            mask="+420 999 999 999"
                            maskChar=" "
                            type="text"
                            name="phoneNumber"
                            placeholder={translate("Enter Phone Number")}
                            value={driverData.phoneNumber}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{translate("WhatsApp")}</Form.Label>
                        <Form.Control
                            type="text"
                            name="whatsapp"
                            placeholder={translate("Enter WhatsApp")}
                            value={driverData.whatsapp}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    {/* <Form.Group className="mb-3">
                        <Form.Label>{translate("Image")}</Form.Label>
                        <Form.Control
                            type="file"
                            name="image"
                            onChange={handleChange}
                        />
                    </Form.Group> */}

                    <div className="d-flex justify-content-between">
                        <Button variant="outline-secondary" type="button" onClick={handleCancel}>
                            {translate("Cancel")}
                        </Button>
                        <Button style={{ background: "rgb(182, 51, 46)", border: "none" }} type="submit">
                            {translate("Submit")}
                        </Button>
                    </div>
                </Form>
            </Container>
        </>
    );
}

export default AddDriver;
