import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Service from './Service';
import moment from 'moment';
import translations from "../translations.json"
import { API_BASE_URL } from '../url';


function Calendar() {
    const [payments, setPayments] = useState({});
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const limit = 10;
    const [offset, setOffset] = useState(0);
    const loader = useRef(null);
    const [cars, setCars] = useState([]);
    const [selectedCarId, setSelectedCarId] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    function translate(key) {
        return translations[key] || key;
    }
    const fetchCars = () => {
        fetch(`${API_BASE_URL}api/cars/getAll?for_select=true`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${document.cookie.split('=')[1]}`,
                "ngrok-skip-browser-warning": "69420",

            }
        })
            .then(response => response.json())
            .then(data => {
                setCars(data);

            })
            .catch(error => console.error("Error fetching cars:", error));
    };

    const fetchData = () => {
        if (loading || !hasMore) return;

        setLoading(true);

        let filters = {};
        if (selectedCarId) {
            filters["car_id"] = selectedCarId;
        }
        if (selectedStatus) {
            filters["status"] = selectedStatus;
        }

        const query = `limit=${limit}&offset=${offset}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
        fetch(`${API_BASE_URL}api/services/getAll?${query}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${document.cookie.split('=')[1]}`,
                "ngrok-skip-browser-warning": "69420",

            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.length === 0) {
                    setHasMore(false);
                } else {
                    const updatedPayments = { ...payments };
                    data.forEach(item => {
                        const month = moment(item.service.deadline).format('MMMM');
                        if (!updatedPayments[month]) {
                            updatedPayments[month] = [];
                        }
                        updatedPayments[month].push(item);
                    });
                    setPayments(updatedPayments);
                    setOffset(prevOffset => prevOffset + 1);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching services:", error);
                setLoading(false);
            });
    };

    const handleCarSelect = (e) => {
        const carId = e.target.value;
        setSelectedCarId(carId);
        setPayments({});
        setHasMore(true);
        setOffset(0);
    };

    const handleStatusSelect = (e) => {
        const status = e.target.value;
        setSelectedStatus(status);
        setPayments({});
        setHasMore(true);
        setOffset(0);
    };

    useEffect(() => {
        fetchCars();
    }, []);

    useEffect(() => {
        fetchData();
    }, [selectedCarId, selectedStatus]);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !loading && hasMore) {
                fetchData();
            }
        }, { rootMargin: '20px' });

        if (loader.current) {
            observer.observe(loader.current);
        }

        return () => {
            if (loader.current) {
                observer.unobserve(loader.current);
            }
        };
    }, [loading, hasMore, payments]);

    return (
        <Container>
            <Form className='filters'>
                <Form.Select onChange={handleCarSelect} value={selectedCarId}>
                    <option value="">Všechny auta</option>
                    {cars.map(car => (
                        <option key={car.id} value={car.id}>{car.brand} {car.model}</option>
                    ))}
                </Form.Select>
                <Form.Select onChange={handleStatusSelect} value={selectedStatus}>
                    <option value="">Všechny statusy</option>
                    <option value="upcoming">Rezervované</option>
                    <option value="enroll">{translate("Enroll")}</option>
                    <option value="urgently">{translate("Urgently")}</option>
                </Form.Select>
            </Form>
            {Object.keys(payments).map((month, index) => (
                <React.Fragment key={month}>
                    <Row>
                        <Col><h2>{translate(month)}</h2></Col>
                    </Row>
                    <Row>
                        {payments[month].map((service, idx) => (
                            <Col key={idx} sm={12} md={6} lg={4}>
                                <Service {...service} />
                            </Col>
                        ))}
                    </Row>
                </React.Fragment>
            ))}
            <Row ref={loader}>
                <Col className="text-center">
                    {loading ? 'Loading...' : ''}
                </Col>
            </Row>
        </Container>
    );
}

export default Calendar;
