import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./driver.css";
import { useNavigate } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import translations from "../translations.json"
import nophoto from "./nophoto.jpg"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { API_BASE_URL } from '../url';


const Driver = ({ cars = [], first_name, phone_number, last_name, experience, categories, park_id, post, salary, whatsapp, id, car_brand, car_id, car_model, photo, CarsData = [], onDriverUpdated }) => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const navigate = useNavigate();

  console.log(cars)

  function EditDriver() {
    navigate(`/edit_driver/${id}`)
  }

  function translate(key) {
    return translations[key] || key;
  }

  const cookie = document.cookie
  let sessionId = cookie.split("=")[1];

  function DeleteDriver() {
    fetch(`${API_BASE_URL}api/drivers/remove`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${sessionId}`,
        "ngrok-skip-browser-warning": "69420",

      },
      body: JSON.stringify({ id: id }),
    })
      .then(response => response.json())
      .then(data => {
        alert('Řidič byl smazán');
        onDriverUpdated();
      })
      .catch(error => {
        console.log(error);
      });
  }




  return (
    <>
      <tr className='TableRow' >
        {/* <td className="py-3 table_text align-middle center_photo">
          <img className='driver_photo' src={photo == null ? nophoto : photo} alt="Driver"></img>
        </td> */}
        <td className="py-3 table_text align-middle">{first_name}</td>
        <td className="py-3 table_text align-middle">{last_name}</td>
        <td className="py-3 table_text align-middle">{phone_number}</td>
        {/* <td className="py-3 table_text align-middle"> */}
        {/* <Form.Group>
            <Form.Select
              aria-label="Select car"
              onChange={handleCarSelectionChange}
              defaultValue={car_id || ""}
            >
              {car_id ? (
                <option value={car_id}>{car_brand} {car_model}</option>
              ) : (
                <option value="">No car selected</option>
              )}
              {CarsData.filter(car => car.id !== car_id).map((car) => (
                <option key={car.id} value={car.id}>{car.brand} {car.model}</option>
              ))}
            </Form.Select>
          </Form.Group> */}

        {/* </td> */}
        <td className="py-3 table_text align-middle">
          <div className='status_active' onClick={handleShow}><VisibilityIcon /></div>
        </td>
      </tr>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{last_name} {first_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="driver-info">
            <div className="d-flex">
              <div className="nameInfoCar">{translate("First Name")}:</div>
              <div className="infoCar">{first_name}</div>
            </div>
            <div className="d-flex">
              <div className="nameInfoCar">{translate("Last Name")}:</div>
              <div className="infoCar">{last_name}</div>
            </div>
            <div className="d-flex">
              <div className="nameInfoCar">{translate("Phone Number")}:</div>
              <div className="infoCar">{phone_number}</div>
            </div>
            {/* <div className="d-flex">
              <div className="nameInfoCar">{translate("Park ID")}:</div>
              <div className="infoCar">{park_id}</div>
            </div> */}
            <div className="d-flex">
              <div className="nameInfoCar">{translate("WhatsApp")}:</div>
              <div className="infoCar">{whatsapp}</div>
            </div>
          </div>
          <div className="d-flex">
            <div className="nameInfoCar">{translate("Car Info")}:</div>
            <div className="infoCar">
              {cars.map((car, index) => (
                <div key={index}>
                  {car[1]} {car[2]}
                </div>
              ))}
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer className='d-flex justify-content-between'>
          <Button variant="outline-secondary" type="button" className='d-flex align-items-center' onClick={DeleteDriver}>
            <DeleteForeverIcon /> {translate("Delete")}
          </Button>
          <button className='edit_modal' onClick={EditDriver}>{translate("Edit driver")}</button>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default Driver;
